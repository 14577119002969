import React, { useState } from "react";
import {
  Alert,
  Box,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  Switch,
  Stack,
  Button,
  ButtonGroup,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { lessonDataActions } from "../store/lessonDataSlice";
import MultiSelectField from "../general/MultiSelectField";
import ListInput from "../general/ListInput";
import LessonSettings from "./LessonSettings";
import {
  PLAN_NAMES,
  LESSON_METADATA_FIELDS,
  SKILL_LEVEL_MAP,
} from "./constants";
import SaveModal from "../general/SaveModal";
import { buildLessonMetadataUpdateBody } from "./utils";
import _ from "lodash";

export default function LessonMetadata({ lesson }) {
  const initialLesson = useSelector((state) => state.lessonData.initialLessons)[
    lesson.id
  ];

  const inProgressChanges = useSelector(
    (state) => state.lessonData.inProgressFields
  )[lesson.id];

  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false);
  const [saveError, setSaveError] = useState("");
  const [saveWarning, setSaveWarning] = useState(false);

  const [languageChangeIntent, setLanguageChangeIntent] = React.useState(null);

  const needsSave = () => {
    const diffs = LESSON_METADATA_FIELDS.map((field) =>
      _.isEqual(lesson[field], initialLesson[field])
    );
    return !diffs.reduce((prev, cur) => prev && cur, true);
  };

  const updateField = (field) => (value) => {
    if (field === "language") {
      if (value === initialLesson.language) {
        setLanguageChangeIntent(null);
      } else {
        setLanguageChangeIntent(value);
      }
    }
    dispatch(
      lessonDataActions.updateLessonField({
        lessonId: lesson.id,
        field: field,
        value: value,
      })
    );
    setSaveWarning(false);
  };

  const save = async (saveMessage = "") => {
    if (inProgressChanges?.length && !saveWarning) {
      setSaveWarning(true);
      return;
    }

    setSaveError("");
    setSaveWarning(false);

    if (typeof saveMessage !== "string" && !(saveMessage instanceof String)) {
      saveMessage = "";
    }
    if (lesson.type === "assessment" && !lesson.related_lesson) {
      setSaveError("An assessment must have a related lesson.");
      return;
    }
    if (lesson.type === "practice_problem" && !lesson.related_lessons) {
      setSaveError("A practice problem must have a related lesson.");
      return;
    }

    setSaving(true);

    try {
      let body = buildLessonMetadataUpdateBody(lesson, saveMessage);
      // Cli lessons don't use package dependency of pycheck to determine when
      // custom checks are enabled, so save them when the field is updated.
      if (lesson.language === "cli") {
        body["custom_check_enabled"] = lesson.custom_check_enabled;
      }
      const response = await fetch(`/api/lesson/${lesson.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      const responseData = await response.json();
      if (responseData.error) {
        setSaveError(responseData.error);
      } else {
        dispatch(
          lessonDataActions.setLessonData({
            lesson: responseData,
            backend: true,
          })
        );
      }
    } catch (error) {
      console.log(error);
      setSaveError(
        "An error occurred while trying to save the lesson. Try again later."
      );
    }

    setSaving(false);
  };

  return (
    <Stack spacing={1} pt={2} className="dq-w-full">
      <Stack direction="row" alignItems="center" spacing={1} my={1}>
        <Typography variant="subtitle2">Release:</Typography>
        <Typography variant="caption">{lesson.release_name}</Typography>
      </Stack>
      <TextField
        label="Title"
        value={lesson.title}
        onChange={(event) => updateField("title")(event.target.value)}
        size="small"
      />
      <TextField
        label="Meta title"
        value={lesson.meta_title}
        onChange={(event) => updateField("meta_title")(event.target.value)}
        size="small"
      />
      <TextField
        label="Author"
        value={lesson.author}
        onChange={(event) => updateField("author")(event.target.value)}
        size="small"
      />
      <TextField
        label="Meta description"
        value={lesson.meta_description}
        onChange={(event) =>
          updateField("meta_description")(event.target.value)
        }
        multiline
        minRows={2}
        maxRows={5}
      />
      <TextField
        label="Description"
        value={lesson.description}
        onChange={(event) => updateField("description")(event.target.value)}
        readOnly={lesson.is_shallow_import}
        multiline
        minRows={2}
        maxRows={5}
      />
      <TextField
        label="Overview"
        value={lesson.overview}
        onChange={(event) => updateField("overview")(event.target.value)}
        readOnly={lesson.is_shallow_import}
        multiline
        minRows={2}
        maxRows={5}
      />
      <TextField
        label="Preview"
        value={lesson.preview}
        onChange={(event) => updateField("preview")(event.target.value)}
        readOnly={lesson.is_shallow_import}
        multiline
        minRows={2}
        maxRows={5}
      />
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={lesson.is_beta}
              onChange={(event) => updateField("is_beta")(event.target.checked)}
              disabled={lesson.is_shallow_import}
            />
          }
          label={"Beta"}
          labelPlacement="end"
        />
      </FormControl>
      <ListInput
        label="Objectives"
        handleOnChange={updateField("objectives")}
        values={lesson.objectives}
        disabled={lesson.is_shallow_import}
        lessonId={lesson.id}
        newValueLabel="Objective"
      />
      <MultiSelectField
        label="Allowed plans"
        selected={lesson.allowed_plans}
        onChange={(event) => updateField("allowed_plans")(event.target.value)}
        values={PLAN_NAMES}
        disabled={lesson.is_shallow_import}
      />
      <FormControl variant="outlined" margin="normal">
        <InputLabel id="skill-level">Skill level</InputLabel>
        <Select
          labelId="skill-level"
          label="Skill level"
          value={lesson.skill_level}
          onChange={(event) => updateField("skill_level")(event.target.value)}
          disabled={lesson.is_shallow_import}
        >
          {Object.keys(SKILL_LEVEL_MAP).map((value) => (
            <MenuItem key={value} value={value}>
              {SKILL_LEVEL_MAP[value]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LessonSettings
        lesson={lesson}
        updateField={updateField}
        unsavedChanges={needsSave()}
      />
      {saveError && (
        <Alert severity="error" onClose={() => setSaveError("")}>
          {saveError}
        </Alert>
      )}

      {needsSave() && (
        <Alert severity="info">The metadata has unsaved modifications</Alert>
      )}
      {languageChangeIntent && (
        <Alert severity="error">
          <Box m={0}>
            Updating the language will cause some answer checking/output fields
            to be lost! "Check code run" code sections will be discarded!
          </Box>
        </Alert>
      )}
      {saveWarning && (
        <Alert severity="warning">
          <Box m={0}>You have incomplete changes for the following fields:</Box>
          <ul>
            {inProgressChanges?.map((fieldName) => (
              <li key={fieldName}>{fieldName}</li>
            ))}
          </ul>
          <Box m={0}>
            Please finalize your changes. Clicking on Save again will store data
            without new values in those fields.
          </Box>
        </Alert>
      )}

      <ButtonGroup fullWidth>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSaveModalIsOpen(true)}
          disabled={saving || saveModalIsOpen || lesson.is_shallow_import}
          size="small"
        >
          Save with message
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={save}
          disabled={saving || lesson.is_shallow_import}
          size="small"
        >
          Save
        </Button>
      </ButtonGroup>
      <SaveModal
        isOpen={saveModalIsOpen}
        close={() => setSaveModalIsOpen(false)}
        save={save}
      />
    </Stack>
  );
}
