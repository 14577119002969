import React from "react";
import LabelledOutline from "../general/LabelledOutline";
import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  TextField,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import MultiSelectField from "../general/MultiSelectField";
import ListInput from "../general/ListInput";
import {
  LANGUAGE_NAMES,
  LESSON_TYPES,
  FEATURES,
  PYTHON_VERSIONS,
  DEFAULT_PYTHON_VERSION,
  SQL_MAX_ROWS,
} from "./constants";

export default function LessonSettings({
  lesson,
  updateField,
  unsavedChanges,
}) {
  const shouldShowWarning = () => {
    return (
      unsavedChanges &&
      lesson.enabled_features.includes("JupyterLab") &&
      lesson.language_version === "3.11"
    );
  };
  const renderType = () => (
    <FormControl margin="normal">
      <InputLabel id="language-label">Type</InputLabel>
      <Select
        labelId="type-label"
        value={lesson.type}
        label="Type"
        onChange={(event) => updateField("type")(event.target.value)}
        disabled={lesson.is_shallow_import}
        size="small"
      >
        {Object.keys(LESSON_TYPES).map((key) => (
          <MenuItem key={key} value={key}>
            <ListItemText primary={LESSON_TYPES[key]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderAssessments = () => {
    if (lesson.type !== "assessment") return null;
    return (
      <TextField
        required
        type="number"
        error={lesson.assessment_error}
        margin="normal"
        variant="outlined"
        name="related"
        label="Related lesson number"
        helperText="Which lesson this assessment is for"
        value={lesson.related_lesson}
        onChange={(event) => updateField("related_lesson")(event.target.value)}
        disabled={lesson.is_shallow_import}
        size="small"
      />
    );
  };

  const renderPracticeProblems = () => {
    if (lesson.type !== "practice_problem") return null;
    return (
      <>
        <ListInput
          label="Related lessons"
          handleOnChange={updateField("related_lessons")}
          values={lesson.related_lessons}
          lessonId={lesson.id}
          disabled={lesson.is_shallow_import}
        />
        <ListInput
          label="Practice skill topics"
          handleOnChange={updateField("skill_topics")}
          values={lesson.skill_topics}
          lessonId={lesson.id}
          disabled={lesson.is_shallow_import}
        />
      </>
    );
  };

  const renderProjectMeta = () => {
    if (lesson.type.indexOf("project") === -1) return null;
    return (
      <>
        <ListInput
          label="Project tools"
          handleOnChange={updateField("tools")}
          values={lesson.tools}
          lessonId={lesson.id}
          disabled={lesson.is_shallow_import}
        />
        <ListInput
          label="Project prerequisites"
          handleOnChange={updateField("skill_requirements")}
          values={lesson.skill_requirements}
          lessonId={lesson.id}
          disabled={lesson.is_shallow_import}
        />
      </>
    );
  };

  const renderProjectNotebook = () => {
    if (lesson.type.indexOf("project") === -1) return null;
    return (
      <TextField
        type="text"
        margin="normal"
        variant="outlined"
        name="solution_notebook"
        label="Solution notebook"
        helperText="Solution notebook url for this project (should be added as a data file)"
        value={lesson.solution_notebook}
        onChange={(event) =>
          updateField("solution_notebook")(event.target.value)
        }
        disabled={lesson.is_shallow_import}
        size="small"
      />
    );
  };

  const renderLanguage = () => {
    return (
      <FormControl variant="outlined" margin="normal">
        <InputLabel id="language-label">Language</InputLabel>
        <Select
          labelId="language-label"
          value={lesson.language}
          label="Language"
          onChange={(event) => updateField("language")(event.target.value)}
          disabled={lesson.is_shallow_import}
          size="small"
        >
          {LANGUAGE_NAMES.map((name) => (
            <MenuItem key={name} value={name}>
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderPythonVersion = () => {
    if (lesson.language !== "python") return null;
    return (
      <FormControl variant="outlined" margin="normal">
        <InputLabel id="python-version-label">Version</InputLabel>
        <Select
          labelId="python-version-label"
          value={lesson.language_version || DEFAULT_PYTHON_VERSION}
          label="Version"
          onChange={(event) =>
            updateField("language_version")(event.target.value)
          }
          disabled={lesson.is_shallow_import}
          size="small"
        >
          {PYTHON_VERSIONS.map((name) => (
            <MenuItem key={name} value={name}>
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderApplicationPorts = () => {
    if (lesson.enabled_features.indexOf("Stateful") === -1) return null;
    return (
      <FormControl variant="outlined" margin="normal">
        <ListInput
          label="Application ports"
          handleOnChange={updateField("app_ports")}
          values={lesson.app_ports}
          disabled={lesson.is_shallow_import}
          lessonId={lesson.id}
          newValueLabel="Application port"
        />
      </FormControl>
    );
  };

  const renderFeatures = () => {
    if (lesson.language === "cli") return null;
    return (
      <MultiSelectField
        label="Features"
        selected={lesson.enabled_features}
        onChange={(event) =>
          updateField("enabled_features")(event.target.value)
        }
        values={FEATURES}
        disabled={lesson.is_shallow_import}
      />
    );
  };

  const renderTimeout = () => {
    if (
      lesson.language === "cli" ||
      lesson.enabled_features.indexOf("Long-running code") !== -1 ||
      lesson.enabled_features.indexOf("File viewer") !== -1
    )
      return null;
    return (
      <TextField
        label="Timeout"
        type="number"
        min="1"
        size="small"
        value={lesson.code_run_timeout}
        onChange={(event) =>
          updateField("code_run_timeout")(event.target.value)
        }
        disabled={lesson.is_shallow_import}
      />
    );
  };

  const renderSQLMaxRows = () => {
    if (lesson.language !== "sql") return null;
    return (
      <Box mt={1}>
        <Typography variant="subtitle2" gutterBottom>
          Max rows
        </Typography>
        <Tooltip
          title="Maximum number of rows to display in the query result"
          arrow
          placement="bottom"
        >
          <Slider
            value={lesson.sql_max_rows || SQL_MAX_ROWS}
            defaultValue={SQL_MAX_ROWS}
            valueLabelDisplay="auto"
            min={1}
            max={100}
            onChange={(event, newValue) =>
              updateField("sql_max_rows")(newValue)
            }
          />
        </Tooltip>
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column" my={6}>
      <LabelledOutline label="Settings">
        <Box display="flex" flexDirection="column">
          {shouldShowWarning() && (
            <Alert severity="warning">
              Please save the lesson to get the updated list of packages for
              this project.
            </Alert>
          )}
          {renderType()}
          {renderAssessments()}
          {renderPracticeProblems()}
          {renderProjectMeta()}
          {renderProjectNotebook()}
          {renderFeatures()}
          {renderLanguage()}
          {renderPythonVersion()}
          {renderTimeout()}
          {renderSQLMaxRows()}
          {renderApplicationPorts()}
        </Box>
      </LabelledOutline>
    </Box>
  );
}
